import React from 'react';
import {Button} from './Button'

const Navbar = () => {

    
    return (
        
        <div className="Navbar">
            <a href="https://tadabbor.echchatt.net" target="_parent"><Button className="NavToApp">القرآن الكريم</Button></a>
            <a href="https://salaat.echchatt.net" target="_blank"><Button className="NavToApp">أوقات الصلاة</Button></a>
        </div>
    )
}

export default Navbar;

/*
    const refreshPage = () => window.location.reload();

<Button onClick={refreshPage}>تنبيه جديد</Button>
*/
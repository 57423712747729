import React, { useState } from 'react'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';

import Verses from './components/Verses'

function App() {

  const [session, Session] = useState(0);

  return (
  <Router>
    <Header/>
      <Verses props={session} /> 
    <Footer/>
  </Router>

  );
}

export default App;

import React from 'react';

const Footer = () => {
    return (
        <div className="Footer">
            <ul title="يجوز الدعاء وطلب الرضى من الله لكل مسلم وإن لم يكن صحابياً وإلى هذا ذهب جمهور العلماء لقول الله سبحانه (إِنَّ الَّذِينَ آمَنُوا وَعَمِلُوا الصَّالِحَاتِ أُولَئِكَ هُمْ خَيْرُ الْبَرِيَّةِ 7 جَزَاؤُهُمْ عِنْدَ رَبِّهِمْ جَنَّاتُ عَدْنٍ تَجْرِي مِنْ تَحْتِهَا الْأَنْهَارُ خَالِدِينَ فِيهَا أَبَداً رَضِيَ اللَّهُ عَنْهُمْ وَرَضُوا عَنْهُ ذَلِكَ لِمَنْ خَشِيَ رَبَّهُ 8) [سورة البينة]">
            <li id="meTime"></li>
                <li>مُختاراتٌ شِعريةٌ لتنبِيهِ الغَافِلين للشيخ مُحَمَّدْ الشَّاطْ رَضِيَ اللَّهُ عَنْه</li>
            </ul>
            
        </div>
    )
}

export default Footer;

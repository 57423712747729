import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from './Button';

import Api from '../Api';

const Verses = ({props}) => {

    const [number, Number] = useState(0)
    const [poem, Poem] = useState(null)
    const [verses, Verses] = useState([])
    const [now, Now] = useState(new Date())
    const [loaded, Load] = useState(false)
    const [show, Show] = useState({i:0, n:6})

    //const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);

    useEffect(() => {

        Api.get(`Poem`)
            .then(response => {
                //console.log('response',response.data)
                Poem(response.data)

                let parts=[]
                let items=response.data.verses
                //alert(items.length)
                for (var i=0; i < items.length; i+=2) {
                    let part={Id:items[i].Id, Part1:items[i].Value, Part2:items[i+1].Value }
                    //console.log(part)
                    parts.push(part)
                }

                Verses(parts)

                Load(true)
                Show({i:0, n:(parts.length*9)})

                Number(number + 1)
            })
            .catch(err => console.error(err))
    }, [loaded]);
    

const postAnswer = (e) => {
    Load(false)
}

useEffect(() => {
    const interval = setInterval(() => {  

        Now(new Date())

    }, 1000)
    return () => clearInterval(interval)
}, []);


useEffect(()=>{

    if(show.n===show.i) {
        Load(false)
    }
    else {
        Show({...show, i:show.i+1})
    }
    var time=document.getElementById("meTime");
    time.innerHTML = now.toLocaleTimeString("en-US",{hour12:false})

    //console.log(show)

},[now])

const onScrollTop = () => {
    if(show.i>2) {
        //alert(show.i)
    }
}

    return (
        <QuizWindow  onScroll={onScrollTop}>
            { poem &&

                <>
                    <Question dangerouslySetInnerHTML={{ __html: `${poem.poet && poem.poet.Name}` }}></Question>                    
                    
                    <Options>

<table>
<thead>
    <tr>
        <th/>        
        <th/>
    </tr>
</thead>

<tbody>

                        {verses.map((item, index) => (
                            <tr key={index} >

        <td style={{alignContent:'right',}}>
            <Option dangerouslySetInnerHTML={loaded ? { __html: item.Part1 }:{ __html: null }} 
                style={{color:(Math.floor(show.i/9)===index && show.i%9<5)?'gold':'silver'}} >
                
                </Option>
        </td>
        <td>
            <Option dangerouslySetInnerHTML={loaded ? { __html: item.Part2 }:{ __html: null }} 
            style={{color:(Math.floor(show.i/9)===index && show.i%9>=5)?'gold':'silver'}}>
                
            </Option>
        </td>

                            </tr>
                        ))}

</tbody>
</table>

                    </Options>

                    <Button onClick={postAnswer}>تنبيه جديد</Button>                      

                </>

            }
        </QuizWindow>
    )
}

export default Verses

const QuizWindow = styled.div`
    text-align: center;
    margin-top: 125px;
    /*height: calc(100%-150px) ;*/
    max-height: calc(100% - 120px - 1.5rem);
    max-height: 460px;
    overflow-y: scroll;

`;

const Options = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 1em auto;
    
    @media screen and (min-width: 1180px) {
        width: 75%;
    }

`;

const Option = styled.button`
    display: block;
    border: 1px solid silver;
    border-radius: 15px;
    padding: 12px 30px;
    text-decoration: none;
    color: silver;
    background-color: #161A31;
    transition: 1s;
    font-size: 1.5em;
    /*font-weight: bold;*/
    outline: none;
    user-select: none;
    margin: 0.5em;
    pointer-events: none;
    cursor: not-allowed;
    max-width: 100%;

    &:hover {
        color: white;
        background-color: #616A94;
    }
`;

const Question = styled.div`
    width: 70%;
    font-size: 2em;
    margin: 0 auto;
    color: #161A31;
`;


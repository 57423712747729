import React from 'react';
import Navbar from './Navbar';

const Header = () => {

    return (
        <div className="Header">
            <a href="/">
                <img src='logo.png' alt="Logo" height="100%" />   
            </a>
             
            <Navbar />
        </div>
    )
}

export default Header;